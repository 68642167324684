<template>
   <!--
        <h2>{{$t('overlays.adjust_timings')}}</h2>
   -->
    <video ref="videoPlayer" id="videoPlayer" muted :src="mediaToEdit.media_url" class="videoPlayer center"></video>
    <p v-if="videoPlayer != null && videoPlayer.currentTime != undefined" style="text-align: center; margin-top: 10px;">{{ renderMsShort(videoPlayer.currentTime*1000) }} | {{ renderMsShort(mediaToEdit.media_duration) }}</p>
    <div id="videoTimeline" @click.stop="seekPlayer" class="videoTimeline center" :style="'--in : '+cursor_in_percent+'%; --out : '+cursor_out_percent+'%; --duration : '+active_part_duration_percent+'%; --relative_progression: '+video_currentTime_full_width_percent+'%; --total_progression: '+video_currentTime_percent+'%'">
        <span id="active_part"></span>
        <span id="player_progression"></span>
        <span id="active_progression"></span>
        <span id="readHead"></span>
        <span id="in"></span>
        <span id="out"></span>
    </div>

    
    <div class="controls center">
        <p>{{$t('overlays.adjust_timing_start')}} : {{ renderMsShort(video_in_ms) }}</p>
        <img src="../../../../../assets/bracket_in.svg" v-tippy="$t('overlay.define_starting_media_time')" @click.stop="defineInTime" style="height: 22px;"/>
        <img v-if="videoPlayerPlaying == true" src="../../../../../assets/pause_blue.svg" @click.stop="playPause"/>
        <img v-else src="../../../../../assets/play_blue.svg" @click.stop="playPause"/>
        <!-- <img src="../../../../assets/reset.png" @click="restartPlayer"/> -->
        <img src="../../../../../assets/reset.png" v-tippy="$t('overlay.go_to_starting_time')" @click.stop="goToIn" style="height: 22px;"/>

        
    </div>
    <div class="actions_buttons center">
        <button class="validate" @click.stop="cancel()">{{$t('Cancel')}}</button>
        <button class="validate" @click.stop="emitOverlaySelected()">OK</button>
    </div>
</template>



<script setup>
import { defineProps, defineEmits, ref, onMounted, computed, onBeforeUnmount } from 'vue';
import { renderMsShort } from "../../../../../lib/date-time-duration";

import * as VueI18n from "vue-i18n"
const { t } = VueI18n.useI18n();

const emit = defineEmits(["finished"])

const { mediaToEdit } = defineProps(["mediaToEdit"]);

const videoPlayer = ref(null);
const mediaEdited = ref();

const cursor_in_percent = ref(50);
//const cursor_out_percent = ref(60);
const timeline_pos = ref({start : -1, end : -1});
//const bracketMoving = ref(null);
const videoPlayerPlaying = ref(false);
const videoCurrentTime = ref(0);

const active_part_duration_percent = computed(()=>{
    return cursor_out_percent.value - cursor_in_percent.value
})

const active_part_duration = computed(()=>{
    return mediaToEdit.media_duration * active_part_duration_percent.value/100
});

const video_relative_currentTime = computed(()=>{
    if (videoCurrentTime.value >= video_in_ms.value){
        return videoCurrentTime.value - video_in_ms.value;
    } else {
        return 0
    }
})

const video_currentTime_percent = computed(()=>{
    return (100*videoCurrentTime.value)/mediaToEdit.media_duration
})

//Current time of player, in percent, relativly to active part
const video_currentTime_full_width_percent = computed(()=>{
    let video_currentTime_relative_percent = (100 * video_relative_currentTime.value)/active_part_duration.value;
    return (active_part_duration_percent.value/100) * video_currentTime_relative_percent
})

const video_in_ms = computed(()=>{
    return mediaToEdit.media_duration * cursor_in_percent.value/100
})

const video_out_ms = computed(()=>{
    return video_in_ms.value + mediaToEdit.appearanceDuration
})

const cursor_out_percent = computed(()=>{
    return (100*video_out_ms.value)/mediaToEdit.media_duration
})


onMounted(async ()=>{
    mediaEdited.value = mediaToEdit; 

    if (mediaToEdit.origin == "pexels"){
        mediaEdited.value.media_url = mediaEdited.value.static_url
    }

    const player = videoPlayer.value;

    document.addEventListener("keydown", handle_keyboardActions)

    player.addEventListener('timeupdate', ()=>{
        if (player.currentTime >= video_out_ms.value/1000){
            goToIn();
        };

        videoCurrentTime.value = player.currentTime*1000

    })

    player.addEventListener('playing', ()=>{
        if (videoPlayerPlaying.value == false){
            videoPlayerPlaying.value = true
        }
    })

    player.addEventListener('pause', ()=>{
        if (videoPlayerPlaying.value == true){
            videoPlayerPlaying.value = false
        }
    });

    if (mediaToEdit.trim_in != null){
        let new_percent = (mediaToEdit.trim_in * 100)/mediaToEdit.media_duration;
        cursor_in_percent.value = new_percent;
    }
    
    //cursor_out_percent.value = ((mediaSelected.trim_in + mediaSelected.appearanceDuration) * 100)/mediaSelected.media_duration;
    
    restartPlayer();
})


onBeforeUnmount(()=>{
    //document.removeEventListener('mousemove', onMouseUpdate, false);
    document.removeEventListener("keydown", handle_keyboardActions)
})

/*

function movingBrackets(bracket = null){
    let e = document.getElementById('videoTimeline');
    let rect = e.getBoundingClientRect();
    timeline_pos.value = {start : rect.left, end : rect.left+e.offsetWidth };
    bracketMoving.value = bracket;
};

function onMouseUpdate(evt){
    let x = evt.clientX;

    //If mouse out of the timeline bar 
    if (x < timeline_pos.value.start || x > timeline_pos.value.end){
        return;
    }
    
    const complete = timeline_pos.value.end - timeline_pos.value.start;
    const lookingFor = x - timeline_pos.value.start - 5;
    let percent_value = (100*lookingFor)/complete;
    
    if (bracketMoving.value == "in" && percent_value < cursor_out_percent.value){
        cursor_in_percent.value = percent_value;
        //restartPlayer();
    }
};

*/

function handle_keyboardActions(e) {
    if (e.code == "ArrowRight") {
        e.preventDefault();
        videoPlayer.value.currentTime = videoPlayer.value.currentTime + 1/25
    } else if (e.code == "ArrowLeft") {
        e.preventDefault();
        videoPlayer.value.currentTime = videoPlayer.value.currentTime - 1/25
        
    }
}

async function seekPlayer(evt){
    let e = document.getElementById('videoTimeline');
    let timelineRect = e.getBoundingClientRect();
    timeline_pos.value = {start : timelineRect.left, end : timelineRect.left+e.offsetWidth };

    let x = evt.clientX;

    //If mouse out of the timeline bar 
    if (x < timelineRect.left || x > timelineRect.left+ timelineRect.width){
        return;
    }
    
    const totalWidth = timeline_pos.value.end - timeline_pos.value.start;
    const lookingFor = x - timeline_pos.value.start;
    let percent_value = lookingFor/totalWidth;

    const videoTime = mediaToEdit.media_duration*percent_value;
    videoPlayer.value.currentTime = videoTime/1000;

    cursor_in_percent.value = percent_value*100;
}

function playPause(){
    if (videoPlayerPlaying.value == true){
        videoPlayer.value.pause();
    } else {
        videoPlayer.value.play();
    }
};

function restartPlayer(){
    videoPlayer.value.pause();
    videoPlayer.value.currentTime = 0;
}

function goToIn(){
    videoPlayer.value.pause();
    videoPlayer.value.currentTime = video_in_ms.value/1000;
};

function defineInTime(){
    cursor_in_percent.value = video_currentTime_percent.value;
}

function cancel(){
    emit('finished', null);
}

function emitOverlaySelected(){
    mediaEdited.value.trim_in = Number(video_in_ms.value.toFixed());
    mediaEdited.value.appearanceDuration = Number(active_part_duration.value.toFixed());
    mediaEdited.value.end = Number((Number(mediaEdited.value.start) + Number(active_part_duration.value)).toFixed());
    emit('finished', mediaEdited.value);
}

</script>

<style lang="scss" src="./editVideo.scss" scoped></style>